import React from 'react';
import logo from '../../assets/logo.png';

import { NavLink } from 'react-router-dom';
import './Navbar.scss';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className='title'>
        <a href='https://docextractor.com'>
          <img src={logo} alt='logo' />
        </a>
      </div>
      <div className='options'>
        <NavLink to={'/'} id='mrz' className={({ isActive }) => (isActive ? 'active option' : 'option')}>
          Text&nbsp;Extract
        </NavLink>
        <NavLink
          to={'/rotate-img'}
          id='rotate-img'
          className={({ isActive }) => (isActive ? 'active option' : 'option')}
        >
          Rotate&nbsp;Image
        </NavLink>
        <NavLink to={'/clean-img'} id='clean-img' className={({ isActive }) => (isActive ? 'active option' : 'option')}>
          Clean&nbsp;Image
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
