// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import api from 'api/api';

import placeholder from '../../assets/placeholder.png';
import sample0 from '../../assets/sample0.jpeg';

import './Imageimport.scss';

const Imageimport = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const fileInput = useRef();
  const imgUp = useRef();

  useEffect(() => {
    fileInput.current.addEventListener('change', function () {
      if (this.files && this.files[0]) {
        imgUp.current.onload = () => {
          URL.revokeObjectURL(imgUp.current.src);
        };

        imgUp.current.src = URL.createObjectURL(this.files[0]);
        imgUp.current.classList.remove('placeholder');
      }
    });
  }, [selectedFile]);

  // useEffect(() => {
  //   fetch(sample0)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       imgUp.current.src = URL.createObjectURL(blob);
  //       const file = new File([blob], 'sample.png', blob);
  //       console.log(file);
  //       setSelectedFile(file);
  //     });
  // }, []);

  const SampleImage = (e)=> {
    e.preventDefault();
    fetch(sample0)
      .then((res) => res.blob())
      .then((blob) => {
        imgUp.current.src = URL.createObjectURL(blob);
        const file = new File([blob], 'sample.png', blob);
        console.log(file);
        setSelectedFile(file);
        imgUp.current.classList.remove('placeholder');
      });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.parentCallback({}, 1);
    const formData = new FormData();
    formData.append('image_file', selectedFile);
    console.log(formData);
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }
    try {
      var config = {
        method: 'POST',
        url: props.endpoint,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response = await api(config);
      props.parentCallback(response.data, 0);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleFileSelect = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const handleSample = (event) => {
    //setSelectedFile(sample);
    handleSubmit(event);
  };

  return (
    <div className='image-comp'>
      <form className='image-up-form' onSubmit={handleSubmit}>
        <div className='label'>
          Instructions :
          <br />
          <br />
          1. UPLOAD a image contaning MRZ (Machine Readable Zone).
          <br />
          2. DOWNLOAD the output to your local machine.
        </div>
        <img id='myImg' className='placeholder' ref={imgUp} src={placeholder} alt='' />
        <div className='btn-wrapper'>
          <label htmlFor='file-upload' className='file-upload'>
            <input type='file' ref={fileInput} id='file-upload' name='filename' onChange={handleFileSelect} />
            Upload Image
          </label>
          <input type='submit' id='submit-btn' value={'Submit Image'} />
        </div>
        <div className='sample'>
          Don't have a Image?{' '}
          <a href='' onClick={(e) => SampleImage(e)}>
            Try Our Sample!
          </a>
        </div>
      </form>
    </div>
  );
};

export default Imageimport;
