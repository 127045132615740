// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import empty from '../../assets/no-data.svg';

import loadIcon from '../../assets/load2.gif';
import './DisplayImg.scss';

const DisplayImg = (props) => {
  const imageRef = useRef();
  const [image, setImage] = useState();
  const saveFile = async () => {
    const response = await fetch(image);
    const imageBlob = await response.blob();
    saveAs(imageBlob, 'output.png');
  };
  useEffect(() => {
    if (props.response.image !== undefined) {
      imageRef.current.src = `https://api.docextractor.com/static/${props.response.image}`;

      imageRef.current.classList.remove('placeholder');

      setImage(imageRef.current.src);
    }
    if (props.load === 1) {
      imageRef.current.classList.remove('placeholder');
      imageRef.current.src = loadIcon;
      
    }
    
  }, [props]);

  const DisplayDownload = () => {
    if (Object.keys(props.response).length !== 0) {
      return (
        <div className='download-btn' onClick={saveFile}>
          Download Image
        </div>
      );
    }
  };

  return (
    <div className='image-disp-comp'>
      <div className='label'>Your Result will be in the form of an Image</div>
      <div className='response-image'>
        <img src={empty} className=' image placeholder' alt='' ref={imageRef} />
      </div>
      <DisplayDownload />
    </div>
  );
};

export default DisplayImg;
