import DisplayDetails from 'components/DisplayDetails/DisplayDetails';
import Imageimport from 'components/Imageimport/Imageimport';
import React, { useState } from 'react';

import './TextExt.scss';

const TextExt = () => {
  const [response, setResponse] = useState({});
  const [load, setLoad] = useState(0);
  const handleCallback = (responseData, loadData) => {
    setResponse(responseData);
    setLoad(loadData);
  };
  return (
    <div className='up-res-wrapper'>
      <div className='upload-card'>
        <Imageimport parentCallback={handleCallback} endpoint={'/mrz'} />
      </div>
      <div className='result-card'>
        <DisplayDetails response={response} load={load} />
      </div>
    </div>
  );
};

export default TextExt;
