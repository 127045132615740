// @ts-nocheck
import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import empty from '../../assets/no-data.svg';
import loadIcon from '../../assets/load2.gif';

import './DisplayData.scss';

const DisplayDetails = (props) => {
  const placeholderImg = useRef();
  const saveData = () => {
    const doc = new jsPDF();
    Object.keys(props.response).map((key, i) => {
      doc.text(key + ': ' + props.response[key], 20, 10 + i * 14, {
        lineHeightFactor: 1.15,
        maxWidth: 300,
      });
      return 0;
    });
    doc.output('dataurlnewwindow', 'mrzData.pdf');
  };
  const DisplayDownload = () => {
    if (Object.keys(props.response).length !== 0) {
      placeholderImg.current.style = 'display:none;';
      return (
        <div className='download-btn' onClick={saveData}>
          Download PDF
        </div>
      );
    }
    if (props.load === 1) {
      placeholderImg.current.src = loadIcon;

    }
    
  };
  return (
    <div className='data-comp'>
      <div className='labelData'>Result Data</div>
      <div className='response-data'>
        <img className='placeholder' src={empty} alt='' ref={placeholderImg}></img>
        {Object.keys(props.response).map((key, i) => (
          <div key={i} className='resp-key-data'>
            {key}: {props.response[key]}
          </div>
        ))}
      </div>
      <DisplayDownload />
    </div>
  );
};

export default DisplayDetails;
