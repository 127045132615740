import DisplayImg from 'components/DisplayImg/DisplayImg';
import ImageimportClean from 'components/ImageimportClean/Imageimport';
import React, { useState } from 'react';
import './TextExt.scss';

const RotateImg = () => {
  const [response, setResponse] = useState({});
  const [load, setLoad] = useState(0);
  const handleCallback = (responseData, loadData) => {
    setResponse(responseData);
    setLoad(loadData);
  };

  return (
    <div className='up-res-wrapper'>
      <div className='upload-card'>
        <ImageimportClean parentCallback={handleCallback} endpoint='/rotate_image' />
      </div>
      <div className='result-card'>
        <DisplayImg response={response} load={load} />
      </div>
    </div>
  );
};

export default RotateImg;
