import Footer from 'components/Footer/Footer';
import CleanImg from 'pages/CleanImg';
import RotateImg from 'pages/RotateImg';
import TextExt from 'pages/TextExt';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<TextExt />} />
          <Route path='/rotate-img' element={<RotateImg />} />
          <Route path='/clean-img' element={<CleanImg />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
